.NewLabel {
    position: absolute;
    top: -27px;
    right: 18px;
    z-index: 1;
}

.Text {
    position: absolute;
    top: 11px;
    left: 0;
    animation: pulse 1.8s infinite linear;
}

.Star1, .Star2 {
    position: absolute;
    width: 20px;
    height: 20px;
    opacity: 0;
    transform: rotateZ(32deg);

    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        opacity: 0.4;
        background: radial-gradient(rgba(255, 253, 118, 1) 45%, rgba(255, 250, 118, 0.2) 75%, rgba(255, 247, 30, 0) 90%);
    }

    &:before {
        left: 0;
        top: 50%;
        width: 100%;
        height: 2px;
    }

    &:after {
        left: 50%;
        top: 0;
        width: 2px;
        height: 100%;
    }

    span {
        display: block;
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 1px);
        height: 4px;
        width: 3px;
        opacity: 0.8;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 7px 4px rgba(255, 246, 10, 0.6);
    }
}

.Star1 {
    top: -4px;
    left: 5px;
    animation: star1 1.8s ease-in-out infinite;
}

.Star2 {
    bottom: -1px;
    left: 15px;
    animation: star2 1.8s ease-in-out infinite;
}

@keyframes pulse {
    0%{ transform: scale(1); }
    20%{ transform: scale(1.25); }
    30%{ transform: scale(1.2); }
    40%{ transform: scale(1.25); }
    50%{ transform: scale(1.2); }
    60%{ transform: scale(1.25); }
    70%{ transform: scale(1.2); }
    80%{ transform: scale(1.25); }
    100%{ transform: scale(1); }
}

@keyframes star1 {
    0%, 40% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        opacity: 1;
        transform: scale(0.3);
    }
    65% {
        opacity: 1;
        transform: rotateZ(34deg) scale(1);
    }
    80% {
        opacity: 1;
        transform: rotateZ(0) scale(0.001);
    }
    100% {
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes star2 {
    0%, 60% {
        opacity: 0;
        transform: scale(0);
    }
    70% {
        opacity: 1;
        transform: scale(0.3);
    }
    85% {
        opacity: 1;
        transform: rotateZ(34deg) scale(1);
    }
    100% {
        opacity: 1;
        transform: rotateZ(0) scale(0.001);
    }
}

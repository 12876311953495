@import "../../../../shared/assets/styles/base-variables";

.ScrollArrows {
    position: fixed;
    right: 35px;
    bottom: 40px;
    z-index: 12;
}

.ArrowUp, .ArrowDown {
    display: block;
    position: relative;
    width: 62px;
    height: 62px;
    cursor: pointer;
    text-align: center;

    &:before {
        content: '';
        position: absolute;
        display: block;
        left: -10px;
        right: -30px;
    }
}

.ArrowUp {
    margin-bottom: 15px;
    &:before {
        top: -20px;
        bottom: -8px;
    }
}

.ArrowDown {
    &:before {
        top: -8px;
        bottom: -30px;
    }
}

.Disabled {
    cursor: default;
    opacity: 0.5;
}

@import "../../../../shared/assets/styles/base-variables";

.PPLiveCasino {
    position: relative;
    padding-top: 20px;
    background: #050a0e url("../../../../shared/assets/images/pp-lc-bg.jpg") repeat-x fixed center;
    background-size: cover;

    @media (min-width: $screen-sm-min) {
        padding-top: 50px;
    }

    @media (min-width: 700px) {
        background-size: contain;
        background-position: center 50px;
    }

    @media (min-width: $screen-md-min) {
        background-position: center 80px;
    }

    > div {
        max-width: 2000px;
        margin-left: auto;
        margin-right: auto;
    }

    :global {
        .game a {
            position: relative;
            z-index: 1;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid #f7e60b;
                z-index: -1;
            }
        }
    }
}

.Logo {
    max-width: 50%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (min-width: $screen-sm-min) {
        margin-bottom: 18px;
    }
}

.LcLoader {
    position: absolute;
}

.Error {
    width: 100%;
    margin-top: 30px;
    font-size: 18px;
    color: #ffffff;
}

@import '../../../../shared/assets/styles/base-variables';

.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .HeaderWrapper {
    position: relative;
    z-index: 1;
  }

  div {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.HeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  background-color: #f2f2f2;

  @media (min-width: 380px) {
    font-size: 16px;
  }

  @media (min-width: $screen-md-min) {
    height: 80px;
    font-size: 28px;
  }
}

.Wrap {
  flex-basis: 20%;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 0;

  &:last-child {
    text-align: right;
  }
}

.Link {
  display: inline-block;
  position: relative;
  line-height: 0;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
  }

  path {
    transition: 0.3s fill ease;
  }

  &:active path {
    fill: #a4a4a4;
  }

  @media (min-width: $screen-md-min) {
    &:hover path {
      fill: #a4a4a4;
    }
  }
}

.TouchScreen .Link {
  &:before {
    top: -22px;
    left: -40px;
    right: -40px;
    bottom: -30px;
  }
}

.Balance {
  text-align: center;

  span {
    font-weight: 700;
  }

  @media (min-width: $screen-md-min) {
    font-size: 24px;
    line-height: 1.2;
  }
}

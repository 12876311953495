.AnimatedStars {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
}

.Star {
    position: absolute;
    width: 1px;
    height: 1px;
    transform: rotateZ(32deg);
    animation: star ease-in-out infinite;

    &:before, &:after {
        content: '';
        display: block;
        position: absolute;
        opacity: 0.4;
        background: radial-gradient(rgba(41, 188, 159, 1) 45%, rgba(41, 188, 159, 0.2) 75%, rgba(41, 188, 159, 0) 90%);
    }

    &:before {
        left: 0;
        top: 50%;
        width: 100%;
        height: 2px;
    }

    &:after {
        left: 50%;
        top: 0;
        width: 2px;
        height: 100%;
    }

    span {
        display: block;
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 1px);
        height: 4px;
        width: 3px;
        opacity: 0.8;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0 0 11px 9px rgba(41, 188, 159, 0.6);
    }
}

@keyframes star {
    0% {
        opacity: 1;
        transform: scale(0.3);
    }
    6%, 18% {
        opacity: 1;
        transform: rotateZ(34deg) scale(0.3);
    }
    8%, 16% {
        opacity: 1;
        transform: rotateZ(34deg) scale(0.6);
    }
    10%, 14% {
        opacity: 1;
        transform: rotateZ(34deg) scale(0.8);
    }
    12% {
        opacity: 1;
        transform: rotateZ(34deg) scale(1);
    }
    20% {
        opacity: 1;
        transform: rotateZ(0) scale(0.001);
    }
    100% {
        opacity: 1;
        transform: rotateZ(0) scale(0.3);
    }
}

.WindowContainer {
    border: none;
    background-color: #fff;
    z-index: 100;
    position: fixed;
    bottom: 65px;
    right: 20px;
    width: 376px;
    max-width: 90%;
    min-height: 250px;
    max-height: 83%;
    box-shadow: rgba(0, 0, 0, 0.16) 0 5px 40px;
    height: calc(100% - 110px);
    border-radius: 8px;
    overflow: hidden;
}

@media screen and (min-width: 40em) {
    .WindowContainer {
        bottom: 75px;
        right: 20px;
        max-width: 376px;
        max-height: 704px;
    }
}

.WindowInfoContainer {
    height: auto;
    min-height: 105px;
    padding: 22px 16px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    color: #1e1e1e;
    text-transform: none;
}

.WindowContainerNotifications {
    border: none;
    background: transparent;
    margin: 0;
    z-index: 2147483000;
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: auto;
    max-width: 90%;
    min-height: 0;
    max-height: 60%;
    box-shadow: none;
    height: 200px;
    overflow: hidden;
}

@media screen and (min-width: 40em) {
    .WindowContainerNotifications {
        max-width: 300px;
        max-height: 400px;
    }
}

.ToggleButtonContainer {
    position: fixed;
    z-index: 100;
    bottom: 5px;
    right: 5px;
}

@media screen and (min-width: 992px) {
    .ToggleButtonContainer {
        bottom: 12px;
        right: 20px;
    }
}

.ToggleButton {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    appearance: none;
    text-align: center;
    line-height: inherit;
    text-decoration: none;
    font-size: inherit;
    color: white;
    background-color: #1890ff;
    box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px;
    transition: all 0.2s ease 0s;
    padding: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    outline: none !important;
    border: none !important;
    user-select: none !important;
}

.ToggleButton:hover {
    opacity: 0.8;
    box-shadow: rgba(0, 0, 0, 0.12) 0 2px 8px;
}

.ToggleButtonLoading svg {
    opacity: 0;
}

.ToggleButtonLoading:before {
    content: '';
    display: block;
    position: absolute;
    top: 19%;
    left: 19%;
    width: 62%;
    height: 62%;
    border: 3px solid;
    border-radius: 50%;
    box-sizing: border-box;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: cssload-spin 575ms infinite linear;
}

.CloseButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    cursor: pointer;
}

@keyframes cssload-spin {
    100%{ transform: rotate(360deg); }
}

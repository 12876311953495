@import "../../../../../shared/assets/styles/base-variables";

.Product {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    img {
        transition: 0.3s transform ease;
    }

    a {
        display: inline-block;
        overflow: hidden;

        &:active {
            img {
                transform: scale(1.05);
            }
        }
    }
}

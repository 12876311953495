@import "../../../../../shared/assets/styles/base-variables";

.TransactionItem {
  display: flex;
  align-items: center;
  height: 54px;
  padding: 7px 15px;
  font-size: 12px;

  @media (min-width: 360px) {
    font-size: 14px;
  }

  h5 {
    font-weight: 700;
  }
}

.Amount {
  font-weight: 700;
}

.Ticket {
  font-weight: 600;
}

.Load {
  border-bottom: 1px solid #f2f2f2;
}

.Unload {
  background-color: #f5fafb;
  border-bottom: 1px solid #fff;

  .Amount {
    color: #3b9ca8;
  }
}

.Icon {
  display: flex;
  align-items: center;
}

.Center {
  flex-grow: 1;
  text-align: left;
  padding-left: 8px;

  @media (min-width: 360px) {
    padding-left: 13px;
  }
}

.Right {
  text-align: right;
}

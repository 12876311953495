@import "../../../../shared/assets/styles/base-variables";

.Onl {
    position: relative;
    padding-top: 35px;
    background: #fff linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(127, 168, 202) 80%);

    h4 {
        margin-top: 12px;
        font-size: 20px;
        color: #ffffff;

        @media (min-width: $screen-md-min) {
            margin-top: 20px;
            font-size: 34px;
        }
    }

    ul + h4 {
        margin-top: 80px;
    }

    :global {
        .new {
            top: -2px;
            right: 30px;
        }
    }
}

.Logo {
    max-width: 80%;
    margin-bottom: 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

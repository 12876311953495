@import "../../../../shared/assets/styles/base-variables";

.NetEnt {
    padding-top: 35px;
    background: rgb(11,39,99);
    background: linear-gradient(0deg, rgba(11,39,99,1) 0%, rgba(61,153,161,1) 100%);
}

.Logo {
    max-width: 52%;
    margin-bottom: 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Info {
    margin-top: 30px;
    font-size: 14px;
    color: #82adf4;
    font-weight: 700;
    font-style: italic;

    @media (min-width: $screen-md-min) {
        margin-top: 70px;
        font-size: 24px;
    }
}

.Info + .Info {
    margin-top: 15px;

    @media (min-width: $screen-lg-min) {
        margin-top: 25px;
    }
}

.Light {
    color: #ffffff;
}

@import "../../../../../shared/assets/styles/base-variables";

.Button {
    display: inline-block;
    padding: 0 20px 3px;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: $white;
    background-color: #0088d4;
    border: 3px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0 12px 13.92px 2.08px rgba(78, 0, 68, 0.5), inset 0 0 32px 0 rgba(0, 38, 115, 0.51);
    text-shadow: 0 0.13em 0 rgba(0, 38, 115, 0.7);
    border-radius: 60px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s opacity ease;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:active {
        opacity: 0.9;
    }

    @media (min-width: $screen-md-min) {
        font-size: 19px;
        &:hover {
            opacity: 0.9;
        }
    }
}

.Disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default;
}

* + .Button {
    margin-top: 14px;
}

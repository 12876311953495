@import "../../../../../shared/assets/styles/base-variables";

.Input {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: #555555;
    border: 1px solid #E5E5E5;
    background-color: #E5E5E5;
    border-radius: 60px;

    &::placeholder {
        font-size: 14px;
        color: #BFBFBF;
        opacity: 1;
    }

    &:-ms-input-placeholder {
        font-size: 14px;
        color: #BFBFBF;
    }

    &::-ms-input-placeholder {
        font-size: 14px;
        color: #BFBFBF;
    }
}

* + .Input {
    margin-top: 6px;
}

.OTPInput {
    position: relative;
    margin-top: 10px;

    .Input {
        padding-right: 130px;
    }
}

.Resend {
    display: block;
    position: absolute;
    top: 1px;
    right: 1px;
    height: 48px;
    padding-left: 18px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 0;
    color: $white;
    background-color: #606060;
    border-radius: 60px;
    text-align: center;
    cursor: pointer;

    span, svg {
        vertical-align: middle;
    }

    svg {
        margin-right: 5px;
    }

    span {
        line-height: 48px;
    }
}

.Count {
    display: inline-block;
    min-width: 57px;
    text-align: right;
}

.Error {
    border-color: #e0060d;
}

.InfoMessage {
    position: relative;
    margin-top: 3px;
    font-size: 12px;
}

.AmountInput {
    position: relative;

    > span {
        display: block;
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        line-height: 50px;
        font-weight: 600;
    }

    .Input {
        padding-left: 35px;
    }
}

.ErrorMessage {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 1.2;
    color: #e0060d;
    text-align: left;
}

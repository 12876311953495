@import "../../../../../shared/assets/styles/base-variables";

.Select {
    //display: block;
    //width: 100%;
    //height: 50px;
    //padding: 10px 20px;
    //font-family: inherit;
    //font-size: 16px;
    //font-weight: 600;
    //color: #555555;
    //border: 1px solid $white;
    //background-color: #E5E5E5;
    //border-radius: 60px;
    //
    //&::placeholder {
    //    font-size: 14px;
    //    color: #BFBFBF;
    //    opacity: 1;
    //}
    //
    //&:-ms-input-placeholder {
    //    font-size: 14px;
    //    color: #BFBFBF;
    //}
    //
    //&::-ms-input-placeholder {
    //    font-size: 14px;
    //    color: #BFBFBF;
    //}
}

.Container {

}

div.Control {
    border: 1px solid $white;
    background-color: #E5E5E5;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    border-radius: 60px;
    text-align: left;
    color: #555555;
    box-shadow: none;

    &:hover {
        border-color: $white;
    }
}

div.Input {
    height: 24px;
}

div.SingleValue {
    color: #555555;
    line-height: 1.25;
}

div.ValueContainer {
    padding: 10px 20px;
}

div.Placeholder {
    color: #BFBFBF;
}

div.Indicator {
    padding-right: 20px;
}

div.Menu {
    border-radius: 20px;
    margin-top: 4px;
    text-align: left;
    overflow: hidden;
}

div.FocusedOption {
    background-color: #e4e4e4;
}

div.SelectedOption {
    background-color: #787878;
}

* + .Select {
    margin-top: 6px;
}

.Error {
    border-color: #e0060d;
}

.InfoMessage {
    position: relative;
    margin-top: 3px;
    font-size: 12px;
}

.ErrorMessage {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 1px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 1.2;
    color: #e0060d;
    text-align: left;
}

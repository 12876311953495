.Loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 11;
    background-color: rgba(#fff, 0.9);
}

.Spinner {
    width: 49px;
    height: 49px;
    margin: 0 auto;
    border: 3px solid rgb(0,0,0);
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: cssload-spin 575ms infinite linear;
    -o-animation: cssload-spin 575ms infinite linear;
    -webkit-animation: cssload-spin 575ms infinite linear;
    -moz-animation: cssload-spin 575ms infinite linear;
}

.Message {
    padding: 20px 10px 50px;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
}

@keyframes cssload-spin {
    100%{ transform: rotate(360deg); }
}

@-o-keyframes cssload-spin {
    100%{ -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-webkit-keyframes cssload-spin {
    100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes cssload-spin {
    100%{ -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

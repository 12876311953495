@import "../../../../shared/assets/styles/base-variables";

.BalanceInfo {
    margin-left: 4px;
    margin-bottom: 15px;
    font-size: 11px;
    color: #606060;
    text-align: left;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

* + .BalanceInfo {
    margin-top: 0;
}

.FullHeight {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.InfoMessage {
    text-align: left;
    margin-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
}

.InfoList {
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;

    > li {
        position: relative;
        padding-left: 15px;
        margin-top: 8px;

        &:before {
            content: '';
            position: absolute;
            top: 8px;
            left: 2px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #000000;
        }
    }

    span {
        font-weight: 600;
    }
}

* + .InfoList {
    margin-top: 20px;
}

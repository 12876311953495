@import "../../../../../shared/assets/styles/base-variables";

.Checkbox {
  text-align: left;

  input {
    position: absolute;
    opacity: 0;

    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      font-weight: 600;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 22px;
      height: 22px;
      border-width: 3px;
      border-style: solid;
      border-radius: 4px;
      transition: background 0.2s ease;
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      opacity: 0.5;
    }

    &:checked + label {
      svg {
        transform: scale(1);
      }
    }
  }

  svg {
    position: absolute;
    left: 5px;
    top: 6px;
    transition: transform 0.2s ease;
    transform: scale(0);
  }
}

* + .Checkbox {
  margin-top: 22px;
}

.ErrorMessage {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 1px;
  font-size: 12px;
  line-height: 1.2;
  color: #e0060d;
  text-align: left;
}

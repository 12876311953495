@import '../../../../shared/assets/styles/base-variables';

.CashOut {
  :global .container {
    max-width: 700px;
  }

  @media (min-width: $screen-md-min) {
    font-size: 16px;
  }
}

.Info {
  font-size: 12px;
  color: #606060;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: $screen-md-min) {
    font-size: 14px;
  }
}

* + .Info {
  margin-top: 12px;
}

.BalanceInfo {
  margin-left: 4px;
  margin-bottom: 15px;
  font-size: 11px;
  color: #606060;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* + .BalanceInfo {
  margin-top: 0;
}

.AddAccountButton {
  display: block;
  margin-top: 18px;
  font-size: 24px;
}

.SubmitButton {
  margin-top: 30px;
}

.rulesWrapper {
  margin: 12px auto 0;
  width: 355px;
  max-width: 100%;
}

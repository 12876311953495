.FrameContainer > div {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: #000000;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.Frame {
    display: block;
    position: absolute;
    top:    0;
    bottom: 0;
    left:   0;
    right:  0;
    width:  100px;
    min-width:  100%;
    height: 100px;
    min-height: 100%;
}

.FullScreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

@import "../../../../../shared/assets/styles/base-variables";

.Game {
    display: block;
    position: relative;
    border: 1px solid transparent;
    border-radius: 3px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: 0.3s border-color ease;
}

a.Game {
    &:active {
        border-color: #a4a4a4;
    }

    @media (min-width: $screen-md-min) {
        &:hover {
            border-color: #a4a4a4;
        }
    }
}

.BoxHead {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 5px;
    color: #fff;

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,.5);
        opacity: .8;
        padding: 1px 8px;
        font-size: 14px;
        min-width: 60px;
        text-align: center;
        border-radius: 58px;

        @media (min-width: $screen-md-min) {
            padding: 3px 10px;
            font-size: 16px;
            min-width: 80px;
        }
    }

    svg + span {
        margin-left: 5px;
    }
}

.BoxFoot {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    min-height: 52px;
    color: #fff;
    background: rgba(0,0,0,.5);
    overflow: hidden;

    @media (min-width: $screen-md-min) {
        min-height: 75px;
    }
}

.LiveLeft, .LiveRight {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 50%;
    text-overflow: ellipsis;
    padding: 0 8px 8px;

    @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        padding: 0 5px 5px;
        font-size: 12px;
    }

    @media (min-width: $screen-lg-min) {
        font-size: 16px;
    }
}

.BjSeats {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: $screen-sm-min) {
        padding-left: 3px;
        padding-right: 3px;
    }

    li {
        width: 14.28571%;
        padding: 0 5px;
        margin-top: 5px;
    }

    svg {
        width: 100%;
        height: auto;
    }
}

.TableClose {
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.4);
    }

    .BoxFoot {
        background: none;
    }
}

.TableMsg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: #ffffff;
    text-align: center;
}

.BaccaratStats {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
    margin-left: -1px;
    margin-bottom: 8px;

    @media (max-width: ($screen-md-min  - 1)) {
        margin-top: 8px;
    }

    @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        font-size: 12px;
    }

    > div {
        flex-grow: 1;
        flex-shrink: 0;

        @media (max-width: ($screen-md-min  - 1)) {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-right: 3px;
            }
        }
    }

    div div {
        position: relative;
        border-left: 1px solid hsla(0,0%,100%,.3);
        border-bottom: 1px solid hsla(0,0%,100%,.3);
        padding-bottom: 100%;

        > span {
            display: block;
            position: absolute;
            left: 1px;
            top: 1px;
            right: 0;
            bottom: 1px;
            border: 1px solid transparent;
            border-radius: 50%;
        }
    }

    :global {
        [class^="P"] {
            border-color: #0271af;
        }

        [class^="B"] {
            border-color: #ba0707;
        }

        [class^="PB"], [class^="BB"], [class^="PE"], [class^="BE"] {
            &:before {
                content: '';
                position: absolute;
                top: -1px;
                left: -1px;
                width: 44%;
                height: 44%;
                border-radius: 50%;
                border: 1px solid #ffffff;
                background-color: #ba0707;
            }
        }

        [class^="PP"], [class^="BP"], [class^="PE"], [class^="BE"] {
            &:after {
                content: '';
                position: absolute;
                right: -1px;
                bottom: -1px;
                width: 44%;
                height: 44%;
                border-radius: 50%;
                border: 1px solid #ffffff;
                background-color: #0271af;
            }
        }

        [class$="1"], [class$="2"] {
            span:before {
                content: '';
                position: absolute;
                transform-origin: center;
                transform: rotate(45deg);
                background-color: #00A300;
            }
        }

        [class$="1"] {
            span:before {
                top: -5%;
                bottom: -10%;
                left: 40%;
                width: 14%;
            }
        }

        [class$="2"] {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                &:before {
                    bottom: -1px;
                    left: 10%;
                    height: 43%;
                    width: 15%;
                }

                &:after {
                    display: block;
                    content: '2';
                    font-size: 9px;
                    line-height: 1;
                }
            }
        }
    }
}

.Banker, .Player, .Tie, .BankerPair, .PlayerPair {
    display: block;
    &:before {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 3px;
        line-height: 18px;
        text-align: center;
        border-radius: 50%;
        font-size: 11px;

        @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
            width: 14px;
            height: 14px;
            line-height: 14px;
            font-size: 10px;
        }
    }
}

.Banker {
    &:before {
        content: 'B';
        background-color: #AF4949;
    }
}

.Player {
    &:before {
        content: 'P';
        background-color: #247CBA;
    }
}

.Tie {
    &:before {
        content: 'T';
        background-color: #309A52;
    }
}

.BankerPair, .PlayerPair {
    position: relative;
    &:before {
        content: '';
        background-color: #BDBDBD;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 7px;
        height: 7px;
        border-radius: 50%;

        @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
            width: 5px;
            height: 5px;
        }
    }
}

.BankerPair {
    &:after {
        top: -1px;
        left: -1px;
        background-color: #AF4949;
    }
}

.PlayerPair {
    &:after {
        bottom: -1px;
        left: 12px;
        background-color: #247CBA;

        @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
            left: 10px;
        }
    }
}

.RouletteResults {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 8px 10px;
    font-size: 12px;

    @media (min-width: $screen-sm-min) {
        padding: 14px 12px;
    }

    @media (min-width: $screen-lg-min) {
        font-size: 14px;
    }

    @media (min-width: $screen-xs-min) and (max-width: 599px), (min-width: $screen-sm-min) and (max-width: 819px) {
        padding: 7px 6px;
        font-size: 10px;
    }

    li:first-child {
        transform-origin: center;
        transform: scale(1.6);
    }

    :global {
        .red {
            color: #f57979;
        }

        .green {
            color: #00A300;
        }
    }
}

.SicBoResults {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 6px 8px 4px;
    text-align: center;

    @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        padding-left: 5px;
        padding-right: 5px;
    }

    @media (min-width: $screen-lg-min) {
        padding: 10px 8px 8px;
    }

    li {
        width: 18px;
        padding-top: 1px;
        padding-bottom: 1px;
        border-radius: 3px;
        font-size: 13px;

        &:not(:last-child) {
            background-color: #7e3c2e;
            margin-bottom: 2px;
        }

        @media (min-width: $screen-md-min) {
            font-size: 10px;
        }

        @media (min-width: $screen-lg-min) {
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 12px;

            &:not(:last-child) {
                margin-bottom: 3px;
            }
        }
    }

    ul:first-child li:last-child {
        background-color: #e7e1d9;
        color: #625d53;
    }
}

li.Highlight:not(:last-child) {
    background-color: #7f1d1d;
}

li.Highlight:last-child {
    color: #d0bf93;
}

.MegaWheelResults {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 9px 8px 8px;
    text-align: center;

    @media (min-width: $screen-xs-min) and (max-width: $screen-sm-min - 1) {
        padding: 9px 5px 5px;
    }

    @media (min-width: $screen-sm-min) {
        padding-top: 12px;
    }

    li {
        position: relative;
        padding: 2px;
        border-radius: 50%;

        &:first-child {
            background-color: #2c2447;
            border: 1px solid #fff;
        }
    }

    span {
        display: block;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        font-size: 14px;
        line-height: 22px;
        background-color: #ffefb8;
        color: #2c2447;

        @media (min-width: $screen-xs-min) and (max-width: $screen-md-min - 1) {
            width: 17px;
            height: 18px;
            font-size: 11px;
            line-height: 18px;
        }

        @media (min-width: $screen-lg-min) {
            width: 26px;
            height: 26px;
            line-height: 26px;
        }
    }

    :global {
        .color-2 {
            background-color: #ffb800;
        }
        .color-5 {
            background-color: #4b92e4;
        }
        .color-8 {
            background-color: #aa38b8;
        }
        .color-10 {
            background-color: #fa5c41;
        }
        .color-15 {
            background-color: #ff7a00;
        }
        .color-20 {
            background-color: #33dea5;
        }
        .color-30 {
            background-color: #7353f4;
        }
        .color-40 {
            background-color: #f9c6ff;
        }
    }
}

.MegaMultiplier {
    min-width: 20px;
    width: 100%;
    padding-top: 1px;
    padding-bottom: 1px;
    background: #2f2261;
    border: 1px solid #ffb700;
    border-radius: 3px;
    position: absolute;
    left: 0;
    bottom: 80%;
    font-size: 8px;
    font-weight: 900;
    color: #ffb700;

    @media (min-width: $screen-sm-min) {
        font-size: 9px;
    }
}

.Placeholder {
    width: 100%;
    padding-bottom: 56%;
}

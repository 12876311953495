@import '../../../../../shared/assets/styles/_base-variables';

.statusWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.error {
  color: $red;
  font-weight: 600;
}

@import "../../../../../shared/assets/styles/base-variables";

.ButtonSecondary {
    display: inline-block;
    padding: 10px 5px;
    border-radius: 26px;
    min-width: 110px;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    color: $white;
    text-align: center;
    cursor: pointer;
    transition: 0.3s background-color ease;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (min-width: $screen-md-min) {
        min-width: 130px;
        font-size: 14px;
    }

    & + .ButtonSecondary {
        margin-top: 10px;
    }
}

div + a.ButtonSecondary {
    margin-top: 17px;
}

span + .ButtonSecondary {
    margin-top: 13px;
}

@import "../../../../shared/assets/styles/base-variables";

.Club888 {
    padding-top: 46px;
    background: #000000 url("../../../../shared/assets/images/club-888-bg.jpg") repeat-x fixed center top;
}

.Logo {
    margin-bottom: 28px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

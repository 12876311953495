@import "../../../../../shared/assets/styles/base-variables";

.Button {
    display: inline-block;
    padding: 10px 14px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: $white;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 60px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s background-color ease;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (min-width: $screen-md-min) {
        font-size: 14px;
    }

    svg, img {
        margin-right: 7px;
    }

    span, svg, img {
        vertical-align: middle;
    }
}

.Disabled {
    pointer-events: none;
    opacity: 0.4;
    cursor: default;
}

* + .Button {
    margin-top: 14px;
}

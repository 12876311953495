@import "../../../../shared/assets/styles/base-variables";

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vh;
    background: rgba($default, 0.8);
    z-index: 11;
    overflow-y: auto;
    text-align: center;

    @media (max-height: 450px) {
        padding-top: 15px;
        align-items: flex-start;
    }

    h3 + * {
        margin-top: 10px;
    }

    :global {
        .button-primary + .button-secondary {
            margin-top: 10px;
        }
    }
}

.ModalBody {
    position: absolute;
    top: 12%;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 500px;
    padding: 50px 20px 20px;
    border-radius: 20px;
    background-color: #f2f2f2;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);

    @media (min-width: $screen-md-min) {
        top: 20%;
    }
}

.Close {
    display: block;
    position: absolute;
    top: 15px;
    right: 12px;
    width: 38px;
    height: 38px;
    cursor: pointer;

    path {
        transition: 0.3s fill ease;
    }

    &:active path {
        fill: #535353;
    }

    @media (min-width: $screen-md-min) {
        &:hover path {
            fill: #535353;
        }
    }
}

.ErrorMessage {
    margin-bottom: 20px;
}

.InfoMessage {
    margin-top: 11px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    text-align: left;
    transition: opacity 300ms, max-height 300ms;

    :global {
        * + .marked-list {
            margin-top: 18px;
        }
    }
}

.InfoActive {
    max-height: 300px;
    opacity: 1;
}

.additionalInfo {
    color: #777777;
}

.Text {
    text-align: left;
    margin: 15px 3px 20px;
}

@import "../../../../shared/assets/styles/base-variables";

.CashInTerminal {
    position: relative;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    font-weight: 600;

    > a {
        display: block;
    }
}

.Input {
    position: relative;
    height: 48px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 20px;
    line-height: 48px;
    color: $dark;
    background-color: #e5e5e5;
    border-radius: 60px;
    text-align: right;
    overflow: hidden;

    span {
        display: inline-block;
        width: 4%;
        text-align: center;
    }

    .Error, .Info {
        width: auto;
        font-size: 11px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        @media (min-width: 340px) {
            font-size: 12px;
        }

        @media (min-width: 380px) {
            font-size: 14px;
        }
    }

    .Info {
        font-weight: 600;
        color: #bfbfbf;
    }
}

.Error {
    font-size: 12px;
    font-weight: 400;
    color: #e0060d;
}

.Input + .Error {
    position: absolute;
    top: 47px;
    left: 0;
    right: 0;
}

.VoucherInput {
    text-align: left;

    span {
        width: 5.8%;

        &:nth-child(4n+5) {
            margin-left: 2.4%;
        }
    }
}

.Keyboard {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4px;
    margin-bottom: 10px;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    div {
        flex-basis: 32%;
        margin-top: 6px;
    }
}

.Key {
    padding-bottom: 1px;
    font-size: 18px;
    line-height: 49px;
    color: #000000;
    background-color: #bfbfbf;
    border-radius: 60px;
    cursor: pointer;
    transition: 0.3s all ease;

    &:active:not(.Disabled) {
        color: $white;
        background-color: #989898;
    }

    @media (min-width: $screen-md-min) {
        &:hover:not(.Disabled) {
            color: $white;
            background-color: #989898;
        }
    }
}

.Green {
    background-color: #00A300;
    color: $white;

    &:active:not(.Disabled) {
        background-color: lighten(#00A300, 5%);
    }

    @media (min-width: $screen-md-min) {
        &:hover:not(.Disabled) {
            background-color: lighten(#00A300, 5%);
        }
    }
}

.Disabled, div.Disabled {
    color: #BFBFBF;
    background-color: #E5E5E5;
    box-shadow: none;
    cursor: default;
}

.Text {
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 17px;
}

.Currency {
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 22px;
    font-size: 20px;
    line-height: 48px;
    color: $dark;
}

@import "../../../../shared/assets/styles/base-variables";

.CashoutTicketDropdown {
    position: fixed;
    top: 50px;
    left: 0;
    right: 0;
    padding: 0 14px 32px;
    background-color: #f2f2f2;
    text-align: center;
    z-index: 6;
    max-height: calc(100vh - 50px);
    overflow-y: auto;

    @media (min-width: $screen-md-min) {
        top: 80px;
        max-height: calc(100vh - 80px);
    }

    > div {
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
    }

    :global {
        * + p {
            margin-top: 20px;
        }

        p + span {
            margin-top: 38px;
        }

        .divider {
            margin-top: 9px;
        }

        .inline-block {
            margin-top: 17px;
        }

        .inline-block + .inline-block {
            margin-top: 12px;
        }

        * + .link {
            margin-top: 26px;
        }
    }
}

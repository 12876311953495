@import "../../../../../shared/assets/styles/base-variables";

.RadioButtonsList {
  text-align: left;
}

.RadioButtonItem {
  position: relative;
  label {
    display: block;
    padding: 3px 35px 3px 10px;
    border: 1px solid #c5c6c6;
    border-radius: 8px;
    line-height: 30px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input + span {
    display: block;
    position: absolute;
    right: 8px;
    top: 50%;
    width: 26px;
    height: 26px;
    margin-top: -13px;
    border: 4px solid;
    border-radius: 50%;
  }

  input:checked + span:before {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border: 6px solid;
    border-radius: 50%;
  }
}

* + .RadioButtonItem {
  margin-top: 14px;
}

* + .RadioButtonsList {
  margin-top: 22px;
}

.ButtonDelete {
  position: absolute;
  right: 45px;
  top: 50%;
  width: 26px;
  height: 24px;
  margin-top: -12px;
  color: #8e8e8e;
  transition: 0.3s color ease;
  cursor: pointer;

  &:hover {
    color: #34302f;
  }

  svg {
    height: 100%;
    width: auto;
  }
}

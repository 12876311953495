@import "../../../../shared/assets/styles/base-variables";

.MethodsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
    max-width: 650px;
  }
}

div.CashinByPhone {
  max-width: 650px;
  > p {
    margin-bottom: 20px;
  }

  :global {
    .button-secondary {
      font-size: 14px;
    }

    * + .button-secondary {
      margin-top: 10px;
    }
  }
}

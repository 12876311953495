@import "../../../../../shared/assets/styles/base-variables";

.BonusLabel {
    display: inline-block;
    z-index: 1;
    color: #ffffff;
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.08em;
    padding: 3px 7px;
    border-radius: 10px;

    @media (min-width: $screen-lg-min) {
        padding: 6px 10px;
        font-size: 14px;
    }
}

@import "../../../../shared/assets/styles/base-variables";

.CashInMethod {
  display: flex;
  padding: 5px 10px;
  border: 1px solid #bfbfbf;
  border-radius: 14px;
  text-align: left;

  span {
    display: block;
  }

  > span:first-child {
    display: flex;
    align-items: center;
    width: 40px;
    flex-shrink: 0;
    svg {
      max-width: 35px;
      max-height: 40px;
    }
  }

  > span:last-child {
    padding: 5px 9px;
  }

  @media (min-width: $screen-xs-min) {
    padding: 8px 28px;

    > span:first-child {
      width: 60px;
      svg {
        max-width: 55px;
        max-height: 60px;
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    > span:first-child {
      width: 80px;
      svg {
        max-width: 65px;
        max-height: 70px;
      }
    }
  }
}

* + .CashInMethod {
  margin-top: 14px;
}

.Title {
  font-size: 16px;
  font-weight: 600;

  @media (min-width: $screen-xs-min) {
    font-size: 20px;
  }

  @media (min-width: $screen-sm-min) {
    font-size: 24px;
  }
}

.Description {
  font-size: 14px;

  @media (min-width: $screen-xs-min) {
    font-size: 16px;
  }

  @media (min-width: $screen-sm-min) {
    font-size: 18px;
  }
}

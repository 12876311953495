@import "../../../../shared/assets/styles/base-variables";

.PlaynGo {
    padding-top: 35px;
    background: #050a0e url("../../../../shared/assets/images/playn-go-bg.jpg") repeat-x center top;
    background-size: 21vw;
}

.Logo {
    max-width: 60%;
    margin-bottom: 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

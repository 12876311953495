@import "../../../../shared/assets/styles/base-variables";

.OnlyPlay {
    position: relative;
    padding-top: 35px;
    background: #091720 linear-gradient(0deg, rgba(31,92,87,1) 0%, rgba(9,22,31,1) 80%);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("../../../../shared/assets/images/only-play-bg.png") no-repeat fixed center bottom;
        background-size: 100% auto;
    }
}

.Logo {
    max-width: 60%;
    margin-bottom: 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

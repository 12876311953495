@import "../../../../../shared/assets/styles/base-variables";

.BackDrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($default, 0.5);
    z-index: 5;
}

@import '../../../../../shared/assets/styles/base-variables';

.root {
  :global .container {
    max-width: 570px;
  }

  :global .divider {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.infoText {
  margin: 0 0 15px 4px;
  font-size: 11px;
  color: #606060;
  text-align: left;
  user-select: none;
}

.link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: #8a8a8a;

  svg {
    min-width: 24px;
  }
}

.balanceInfo {
  font-size: 20px;
  font-weight: 600;

  @media (max-width: $screen-xs-min) {
    font-size: 18px;
  }
}

.editIcon {
  margin-bottom: 2px;
  padding-bottom: 2px;
}

.valueWithMargin {
  margin-right: 22px;
}

@import "../../../../shared/assets/styles/base-variables";

.VisoGames {
    padding-top: 35px;
    background: #0e0e32 url("../../../../shared/assets/images/viso-games-bg.jpg") no-repeat center top;
    background-size: 100% auto;
}

.Logo {
    max-width: 60%;
    margin-bottom: 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Info {
    margin-top: 30px;
    font-size: 14px;
    color: #5b7baf;
    font-weight: 700;
    font-style: italic;

    @media (min-width: $screen-md-min) {
        margin-top: 70px;
        font-size: 24px;
    }
}

.Info + .Info {
    margin-top: 15px;

    @media (min-width: $screen-lg-min) {
        margin-top: 25px;
    }
}

.Light {
    color: #31aecd;
}

@import "../../../../../shared/assets/styles/base-variables";

.ScreenKeyboard {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    background-color: #ececec;
    z-index: 1;

    :global {
        .hg-button {
            font-size: 18px;
        }
    }

    @media (min-height: 820px) {
        height: 288px;

        :global {
            .hg-button {
                height: 50px;
                font-size: 22px;
            }
        }
    }

    > div {
        flex-grow: 1;
    }
}

.Small {
    > div {
        max-width: 500px;
    }

    :global {
        .hg-button {
            flex-basis: 33.33%;
        }
    }
}

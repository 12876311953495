@import "../../../../../shared/assets/styles/base-variables";

.Game {
    display: inline-block;
    position: relative;
    max-width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: 0.3s transform ease;

    &:active {
        transform: scale(1.05);
    }

    @media (min-width: $screen-md-min) {
        &:hover {
            transform: scale(1.05);
        }
    }

    :global {
        .placeholder {
            position: relative;
            max-width: 100%;
            overflow: hidden;
            font-size: 18px;
            background-color: #a2a2a2;
            color: #ffffff;

            @media (min-width: $screen-md-min) {
                font-size: 22px;
            }

            span {
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 50%;
                padding: 10px;
                transform: translateY(-50%);
                text-align: center;
            }
        }

        .bonus-label {
            position: absolute;
            top: -7px;
            left: -7px;
        }
    }
}

.Loto {
    position: relative;
    font-size: 2.6vw;

    @media (min-width: 480px) {
        font-size: 1.7vw;
    }

    @media (min-width: 768px) {
        font-size: 1.2vw;
    }

    @media (min-width: 1280px) {
        font-size: 16px;
    }

    h5 {
        position: absolute;
        left: 16%;
        right: 16%;
        top: 68%;
        font-size: 100%;
        font-weight: 700;
        color: #ffffff;
        text-shadow: 0 0.13em 0 rgb(10, 61, 116);

        span {
            display: block;
        }
    }

    img {
        position: relative;
    }
}

.Digit1, .Digit2 {
    position: absolute;
    width: 12%;
    color: #000000;
    font-weight: 700;
    text-align: center;
}

.Digit1 {
    right: 9%;
    bottom: 30%;
    transform: rotate(-8deg);
    font-size: 150%;
}

.Digit2 {
    right: 2.2%;
    bottom: 7%;
    transform: rotate(16deg);
    font-size: 125%;
}

.LotoFlag {
    position: absolute;
    top: 2%;
    left: 24.5%;
    right: 24.5%;
    padding-bottom: 48%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
}

.Auth {
    :global .container {
        display: flex;
        align-items: flex-end;
        max-width: 500px;

        > * {
            width: 100%;
        }

        @media (min-height: 480px) {
            min-height: 190px;
            margin-top: 7vh;
        }

        @media (min-height: 700px) {
            margin-top: 14vh;
        }
    }

    label {
        font-size: 16px;
    }

    h4:first-child {
        margin-top: 17px;
    }

    p + form {
        margin-top: 15px;
    }
}

@import "../../../../shared/assets/styles/base-variables";

.InfoPage {
    text-align: left;

    :global .container {
        max-width: 780px;
    }

    p span {
        font-weight: 600;
    }

    @media (min-width: $screen-md-min) {
        font-size: 16px;
        line-height: 1.3;
    }
}

.Rule {
    margin-bottom: 30px;
}

.Paragraph {
    margin-top: 10px;

    span {
        margin-right: 7px;
    }

    > * {
        vertical-align: middle;
    }
}

@import "../../../../shared/assets/styles/base-variables";

.PTC {
    padding-top: 18px;
    background: #051316 url("../../../../shared/assets/images/ptc-bg.jpg") repeat-x center top;

    :global {
        ul {
            .game.ptc a {
                border: 1px solid $white;
                border-radius: 4px;
                padding: 1px;

                @media (min-width: $screen-lg-min) {
                    padding: 7px 22px;
                }
            }

            .placeholder {
                font-size: 18px;
                border-radius: 4px;
            }
        }
    }
}

.Logo {
    margin-bottom: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@import "../../../../shared/assets/styles/base-variables";

.GreenDream {
    padding-top: 22px;
    background: #002300 url("../../../../shared/assets/images/green-dream-bg.jpg") repeat-x center top;
}

.Logo {
    margin-bottom: 33px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

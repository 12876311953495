@import "../../../../shared/assets/styles/base-variables";

.Maintenance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10%;
    font-size: 16px;
    line-height: 1.5;
    h2 {
        font-size: 20px;
    }

    @media (min-width: $screen-sm-min) {
        font-size: 18px;

        h2 {
            font-size: 30px;
        }

    }
}

@import "../../../../shared/assets/styles/base-variables";

.BonusBannerBase {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 326px;
  max-width: 70%;
  margin-top: 15px;
  transform: translateX(-50%);
  cursor: pointer;

  @media (min-width: $screen-xl-min) {
    top: 5px;
    left: 3%;
    margin-top: 0;
    margin-left: 165px;
    width: auto;
    transform: none;
    z-index: 1;
  }

  .Content {
    top: 50%;
    right: 10%;
    transform: translateY(-52%);
    font-size: 16px;

    @media (min-width: $screen-xs-min) {
      font-size: 20px;
    }

    span {
      font-size: 135%;
      line-height: 1;
      letter-spacing: 0.02em;
    }
  }
}

.Content {
  position: absolute;
  color: $white;
  line-height: 1.15;
  font-weight: 700;
  text-align: center;
  text-shadow: 0 0.13em 0 rgba(0, 38, 115, 0.7);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  span {
    color: #fff232;
  }
}

.Frame {
  box-shadow: 0 0 12px 4px rgba(164, 70, 154, 0.7);
  border-radius: 9px;
}

.Arrow {
  position: absolute;
  top: 15%;
  left: 100%;
  width: 20.6%;
  margin-left: 3px;
  animation: ease infinite alternate;
  animation-name: arrow;
  animation-duration: 0.3s;
}

.Bonus {
  position: absolute;
  top: -2%;
  left: -2%;
  width: 52%;
  transform-origin: center;
  animation: ease infinite alternate;
  animation-name: pulse;
  animation-duration: 0.3s;
}

.BonusAmount {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 60%;
  font-size: 18vw;
  line-height: 1;
  font-weight: 800;
  letter-spacing: 0.02em;
  text-align: center;
  color: #fff232;
  -webkit-text-stroke: 4px #980a7d;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (min-width: $screen-xs-min) {
    font-size: 92px;
  }

  @media (max-height: 550px) and (min-width: 520px) {
    font-size: 15vh;
  }
}

.DontMissBanner {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  margin-top: 30px;
  transform: translateX(-50%);
  z-index: 11;

  .Button {
    display: block;
    position: absolute;
    left: 27%;
    bottom: 14%;
    width: 46%;
  }
}

.BonusBanner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 550px;
  max-width: 110%;

  .Button {
    display: inline-block;
    font-size: 7vw;
    line-height: 1;
    padding: 1vw 28px;

    @media (min-width: $screen-xs-min) {
      border-width: 4px;
      font-size: 30px;
      padding: 12px 30px;
    }

    @media (max-height: 550px) and (min-width: 520px) {
      font-size: 6vh;
      padding: 1vh 4vh;
      border-width: 3px;
    }
  }
}

.CongratsBanner {
  position: relative;
  width: 400px;
  max-width: 84%;
  margin-top: 50px;
  border-radius: 10px;
  background: #560043 radial-gradient(circle, rgba(164, 70, 154, 1) 20%, rgba(86, 0, 67, 1) 100%) top center;
  background-size: 100% 200%;
  box-shadow: 0 0 21px 0 rgba(78, 0, 70, 1) inset;
  text-align: center;

  @media (max-height: 500px) and (min-width: $screen-xs-min) {
    max-width: 92vh;
    margin-top: 30px;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    z-index: -1;
  }

  &:before {
    top: -19px;
    left: -17px;
    right: -17px;
    bottom: -19px;
    background-color: #fff590;
    border: 6px double #732c01;
    border-radius: 18px;
    box-shadow: 0 0 28px 10px rgba(164, 70, 154, 0.7);
  }

  &:after {
    top: -13px;
    left: -11px;
    right: -11px;
    bottom: -13px;
    background-color: #fff55d;
    box-shadow: 0 0 4px 3px #b08125 inset;
    border-radius: 11px;
  }

  > div {
    padding: 6vw 15px 7vw;

    @media (min-width: 400px) {
      padding-top: 30px;
      padding-bottom: 24px;
    }

    @media (max-height: 500px) and (min-width: $screen-xs-min) {
      padding-top: 6vh;
      padding-bottom: 7vh;
    }

    &:before, &:after {
      content: '';
      position: absolute;
      border: 0 dotted #cba249;
      animation: ease infinite alternate;
      animation-name: border-light;
      animation-duration: 0.3s;
    }

    &:before {
      top: -11px;
      left: 3px;
      right: 3px;
      bottom: -11px;
      border-bottom-width: 9px;
    }

    &:after {
      top: 3px;
      left: -10px;
      right: -10px;
      bottom: 3px;
      border-left-width: 9px;
      border-right-width: 9px;
    }
  }

  h3 {
    position: absolute;
    top: -10vw;
    left: 0;
    right: 0;
    font-size: 14vw;
    font-weight: 700;
    line-height: 1;
    color: #fdef04;
    -webkit-text-stroke: 0.25em #980a7d;
    text-shadow: 0 0.18em 0.18em rgba(0, 0, 0, 1);
    z-index: 1;

    @media (min-width: $screen-xs-min) {
      top: -48px;
      font-size: 68px;
    }

    @media (max-height: 500px) and (min-width: $screen-xs-min) {
      top: -10vh;
      font-size: 14vh;
    }

    &:before, &:after {
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 0 dotted #cba249;
      text-shadow: none;
    }

    &:before {
      -webkit-text-stroke: 0.12em #ffffff;
    }

    &:after {
      background: linear-gradient(180deg, #d69c03 0%, #fef156 50%, #d69c03 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke: 0.03em #c15200;
    }
  }

  h4 {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 11vw;
    font-weight: 700;
    line-height: 1.27;
    color: #ffffff;
    animation: ease infinite alternate;
    animation-name: pulse;
    animation-duration: 0.3s;

    @media (min-width: 400px) {
      font-size: 46px;
    }

    @media (max-height: 500px) and (min-width: $screen-xs-min) {
      font-size: 9vh;
    }

    span {
      display: block;
      position: relative;
      -webkit-text-stroke: 0.2em #480a55;
      text-shadow: -0.15em -0.23em 0.3em #ff39e9, 0.15em -0.23em 0.3em #ff39e9, -0.15em 0.25em 0.3em #ff39e9, 0.15em 0.25em 0.3em #ff39e9;

      &:after {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-shadow: none;
        -webkit-text-stroke: 0 #ffffff;
      }
    }

    .Primary {
      -webkit-text-stroke: 0.35em #980a7d;
      text-shadow: -0.22em -0.13em 0.3em #ff39e9, 0.22em -0.13em 0.3em #ff39e9, -0.22em 0.23em 0.3em #ff39e9, 0.22em 0.23em 0.3em #ff39e9;

      &:before {
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-shadow: none;
        -webkit-text-stroke: 0.16em #ffffff;
      }

      &:after {
        background: linear-gradient(180deg, #840777 0%, #9b3490 50%, #840777 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  p {
    margin: 0;
    color: $white;
    font-size: 7vw;
    line-height: 1.15;
    font-weight: 700;
    text-align: center;
    text-shadow: 0 0.15em 0 #220440;

    @media (min-width: 400px) {
      font-size: 32px;
    }

    @media (max-height: 500px) and (min-width: $screen-xs-min) {
      font-size: 6vh;
    }
  }

  .Button {
    position: absolute;
    left: 50%;
    bottom: -44px;
    transform: translateX(-50%);
    padding: 10px 30px;
    border-width: 4px;
    font-size: 28px;

    @media (max-height: 380px) and (min-width: $screen-xs-min) {
      bottom: -10vh;
      padding: 2vh 20px;
      font-size: 7vh;
    }
  }

  &.Safari {
    h3 {
      text-shadow: none;
    }

    h4 span {
      text-shadow: none;
    }
  }
}

.GetBonus {
  position: absolute;
  left: 50%;
  top: 25%;
  width: 485px;
  max-width: 90%;
  transform: translateX(-50%);

  @media (max-height: 550px) {
    top: 80px;
  }

  @media (max-height: 450px) {
    max-width: 92vh;
  }

  @media (max-height: 320px) {
    top: 60px;
  }

  .Content {
    left: 0;
    right: 0;
    bottom: 37%;
    font-size: 12vw;
    text-align: center;

    @media (min-width: $screen-xs-min) {
      font-size: 60px;
    }

    @media (max-height: 450px) and (min-width: 450px) {
      font-size: 14vh;
    }
  }

  :global {
    .slide-up-2 {
      position: absolute;
      top: 23%;
      left: 17%;
      width: 66%;
    }
  }

  .Substrate {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 68.067%;
    z-index: -2;
    border-radius: 10px;

    img {
      position: absolute;
      height: auto;
    }

    :global {
      .img-1 {
        top: 28.5%;
        left: 15.46%;
        width: 69.08%;
        z-index: -2;
      }

      .img-2 {
        left: 0;
        top: 0;
        width: 100%;
      }
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 27%;
      left: 15.5%;
      width: 69%;
      height: 46%;
      border-radius: 18px;
      background-color: #ffffff;
      z-index: -1;
      opacity: 0;
      transform-origin: center;
      animation: ease infinite alternate;
      animation-name: fade;
      animation-duration: 0.2s;
    }
  }
}

.BonusBanner {
  top: 8%;

  @media (min-width: $screen-lg-min) {
    top: 20%;
  }

  @media (max-height: 770px) {
    top: 5%;
  }

  @media (max-height: 620px) {
    top: -5%;
  }

  @media (max-height: 550px) and (min-width: 520px) {
    max-width: 100vh;
  }

  .Close {
    display: block;
    position: absolute;
    top: 12%;
    right: 7%;
    width: 10.548%;
    cursor: pointer;
    z-index: 1;

    @media (min-width: 580px) {
      top: 7.5%;
      right: 1%;
    }
  }

  .Link {
    margin-top: 18px;
    margin-bottom: 30px;
  }

  .Content {
    top: 0;
    left: 0;
    right: 0;
    padding-top: 34%;
    font-size: 6vw;

    @media (min-width: $screen-xs-min) {
      font-size: 30px;
    }

    @media (max-height: 550px) and (min-width: 520px) {
      font-size: 5vh;
    }
  }

  .Button {
    margin-top: 7px;
  }
}

.Link {
  display: inline-block;
  font-size: 26px;
  cursor: pointer;
  color: #A9499D;
  text-decoration: underline;

  &:active {
    color: lighten(#A9499D, 10%);
  }

  @media (min-width: $screen-xs-min) {
    font-size: 30px;
  }

  @media (min-width: $screen-md-min) {
    &:hover {
      color: lighten(#A9499D, 10%);
    }
  }
}

.BannerWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($default, 0.8);
  overflow-y: auto;
  text-align: center;
}

.BannerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
}

.InfoBannerWrap {
  display: flex;
  justify-content: center;
}

.InfoBanner {
  position: relative;

  .Content {
    left: 8%;
    right: 8%;
    bottom: 50%;
    font-size: 6vw;
    text-align: center;
    transform: translateY(50%);

    span {
      display: inline-block;
    }

    @media (min-width: $screen-xs-min) {
      font-size: 28px;
    }

    @media (min-width: $screen-md-min) {
      font-size: 33px;
    }

    @media (max-width: $screen-md-min - 1) {
      br {
        display: none;
      }
    }
  }

  .Button {
    position: absolute;
    left: 50%;
    bottom: 6%;
    transform: translateX(-50%);
    padding: 8px 12px;
    font-size: 17px;
    line-height: 1;

    @media (min-width: $screen-xs-min) {
      padding: 8px 14px;
      font-size: 24px;
    }

    @media (min-width: $screen-md-min) {
      bottom: 11%;
      padding: 12px 16px;
      font-size: 28px;
    }
  }
}

.InfoModal {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translateX(-50%);
  width: 550px;
  max-width: 100%;

  .Close {
    display: block;
    position: absolute;
    top: 6%;
    right: 3%;
    width: 10.548%;
    cursor: pointer;
    z-index: 1;

    @media (min-width: 580px) {
      top: -5.5%;
      right: -2%;
    }
  }

  .Content {
    top: 0;
    left: 0;
    right: 0;
    padding-top: 11%;
    font-size: 6vw;

    @media (min-width: $screen-xs-min) {
      font-size: 30px;
    }
  }

  .Button {
    display: block;
    position: absolute;
    left: 34%;
    bottom: 5%;
    width: 32%;
    font-size: 7vw;
    line-height: 1;
    padding: 1vw 28px;

    @media (min-width: $screen-xs-min) {
      border-width: 4px;
      font-size: 24px;
      padding: 10px 30px;
    }
  }
}

@keyframes arrow {
  100% {
    transform: translateX(10px);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.08);
  }
}

@keyframes fade {
  100% {
    opacity: 1;
  }
}

@keyframes border-light {
  0% {
    border-color: #ffffff;
  }
}

@import "../../../../shared/assets/styles/base-variables";

.ProductsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-lg-min) {
        max-width: 1410px;
    }

    li {
        padding-left: 10px;
        padding-right: 10px;

        @media (min-width: $screen-xs-min) {
            flex-basis: 50%;
            max-width: 50%;
        }

        @media (min-width: $screen-md-min) {
            flex-basis: 33.33%;
            max-width: 33.33%;
        }
    }
}

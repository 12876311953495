@import "../../../../shared/assets/styles/base-variables";

.WhatsApp {
    display: block;
    position: fixed;
    right: 5px;
    bottom: 5px;
    z-index: 1;

    @media (min-width: $screen-md-min) {
        right: 20px;
        bottom: 12px;
    }
}

@import "../../../../shared/assets/styles/base-variables";

.GamesList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: -8px;
    margin-right: -8px;

    @media (min-width: $screen-md-min) {
        margin-left: -15px;
        margin-right: -15px;
    }

    li {
        flex-basis: 50%;
        max-width: 50%;
        padding-left: 8px;
        padding-right: 8px;
        margin-top: 19px;

        @media (min-width: $screen-xs-min) {
            flex-basis: 33.33%;
            max-width: 33.33%;
        }

        @media (min-width: $screen-sm-min) {
            flex-basis: 25%;
            max-width: 25%;
        }

        @media (min-width: $screen-md-min) {
            padding-left: 15px;
            padding-right: 15px;
            margin-top: 35px;
        }
    }

    :global .game.ptc a {
        border: 2px solid #7c7c7c;
        border-radius: 4px;
        padding: 4px 19px;

        @media (min-width: $screen-lg-min) {
            padding: 25px 40px;
        }
    }
}

@import "../../../../shared/assets/styles/base-variables";

.Footer {
    padding: 10px 25px;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;

    p {
        margin: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    @media (min-width: $screen-md-min) {
        font-size: 20px;
    }
}

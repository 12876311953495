@import "../../../../shared/assets/styles/base-variables";

.IdleModal {
    h2 {
        font-size: 30px;
    }

    p {
        font-size: 22px;
    }
}

.Timer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    svg {
        margin-right: 10px;
    }

    span {
        display: inline-block;
        font-size: 22px;
        line-height: 40px;

        span {
            font-size: 36px;
            font-weight: 600;
        }
    }
}

.Inline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        flex-basis: 48%;
        margin: 0;
        padding: 15px;
        font-size: 18px;
    }
}

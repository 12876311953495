//========================================================
//                     Variables
//=========================================================

// Toastify variables
:root {
  --toastify-toast-top: max(3px, env(safe-area-inset-top));
}

// Resolutions
$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1520px;

// Fonts
$default-font-family: 'Open Sans', sans-serif;
$default-font-weight: 400;
$default-font-size: 14px;
$default-line-height: 1.357;

// Anchor Colors
$default: #000;
$contrast: #fff;
$white: #fff;
$dark: #34302f;
$light: #c5c6c6;
$gray: #4a4543;
$red: #ef4444;
$redDark: #cf2222;

$content-color: #34302f;
$content-bg: lighten(saturate(adjust_hue($contrast, 0), 0%), 20%);

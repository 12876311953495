@import "../../assets/styles/variables";

.MainMenu {
    display: flex;
    flex-direction: column;
    padding: 0 0 22px;

    > * {
        flex-basis: 100%;
    }

    h4 {
        margin-top: 28px;
        margin-bottom: 18px;
        color: $secondary;
    }

    svg + h4 {
        margin-top: 22px;
    }

    :global {
        .game {
            margin-top: 8px;
        }

        .game.ptc a {
            border: none;
            padding: 0;
        }

        .placeholder {
            border-radius: 30px;
        }

        .new {
            top: -2px;
            right: 30px;
        }

        .container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    @media (min-width: $screen-sm-min) {
        padding-bottom: 40px;
    }

    @media (min-width: $screen-md-min) {
        padding: 0 0 100px;

        h4 {
            margin-top: 72px;
            margin-bottom: 26px;
            font-size: 34px;
        }

        svg + h4 {
            margin-top: 72px;
        }

        :global {
            .game {
                flex-basis: 20%;
                max-width: 20%;
                margin-top: 40px;
            }

            .container {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.Banner {
    margin-bottom: -8%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    img {
        width: 100%;
        height: auto;
    }
}

.Divider {
    margin-top: 5px;

    @media (min-width: $screen-sm-min) {
        margin-top: 22px;
    }

    @media (min-width: $screen-md-min) {
        width: 690px;
        height: 42px;
        margin-top: 55px;
    }
}
